<template>
    <div>
        <v-data-table
            @click:row="$emit('clickRow', $event)"
            fixed-header
            :headers="headers"
            :items="dados"
            :mobileBreakpoint="0"
            :loading="loading"
            :height="$vuetify.breakpoint.height - height"
            :disable-pagination="true"
            :hide-default-footer="true"
            :options.sync="options"
        >
            <template
                v-for="field in dynamicSlots"
                v-slot:[field.name] = "{ item }"
            >
                <slot
                    :name="field.id"
                    :item="item"
                    :field="field"
                />
            </template>
        </v-data-table>
        <Paginate
            v-model="pagination"
            @input="consultar"
        />
    </div>
</template>

<script>
import Paginate from '@/components/Paginate';

export default {
    name: 'DataTable',

    components: {
        Paginate,
    },

    props: {
        value: {
            type: String,
            default: '',
        },

        headers: {
            type: Array,
        },

        height: {
            type: Number,
            default: 0,
        },

        resource: {
            type: String,
        },

        dynamicSlots: {
            type: Array,
        },
    },

    data: () => ({
        dados: [],
        loading: false,
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    mounted() {
        this.consultar();
    },

    methods: {
        async consultar() {
            this.loading = true;
            this.dados = [];

            const sortBy = this.options.sortBy.length ? `&sortField=${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';
            const params = `page=${this.pagination.current_page}&q=${this.value || ''}${sortBy}${sortOrder}`;
            const operator = this.resource.includes('?') ? '&' : '?';

            await this.$http.get(`${this.resource}${operator}${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.loading = false;
            });
        },
    },
}
</script>
